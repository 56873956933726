//convermax workaround for lack of css module import info experiments.css
var css = "\nhead{--webpack-convermax-client-ui-712:_5576,_9352,_1656,_4336,_1062,_802,_9688,_2584;}"
var style = document.createElement('style');
style.appendChild(document.createTextNode(css));
document.head.appendChild(style);
try{
        if(!getComputedStyle(document.head).getPropertyValue('--webpack-convermax-client-ui-712')) {
          document.head.style.setProperty('--webpack-convermax-client-ui-712', "_5576,_9352,_1656,_4336,_1062,_802,_9688,_2584");
        }
      }catch (ex) {
        console.error(ex);
      }