import shopifyGenericDefaults from '../shopify-generic/config.js';

const isCartPage = window.location.pathname === '/cart';

const getItemId = () => {
  const shopifyProduct = window.ShopifyAnalytics?.meta.product;

  if (document.querySelector('body.hdt-page-type-product hdt-variant-picker')) {
    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);
    const variantId = urlParams.get('variant');

    if (variantId) {
      return variantId;
    }

    if (shopifyProduct.variants.length > 0) {
      return shopifyProduct.variants[0].id;
    }
  }

  if (shopifyProduct) {
    return `${shopifyProduct.id}`;
  }
  console.warn('window.ShopifyAnalytics not found');
  return '';
};

globalThis.Convermax.onCartDialogSubmit = (e) => {
  e.preventDefault();

  const formData = new FormData(e.target);
  const selectedOption = formData.get('vehicle-option');
  const customInput = formData.get('custom-vehicle-input')?.trim();

  if (selectedOption === 'select-vehicle') {
    const selectVehicleButton = document.querySelector(
      '.cm_vehicle-dialog-form .cm_vehicle-widget_button__go:not([disabled])',
    );
    if (selectVehicleButton) {
      selectVehicleButton.click();
    }
  } else if (selectedOption === 'type-vehicle') {
    setCustomVehicleAtShopifyCart(customInput);
    localStorage.setItem('cm-user-vehicle', customInput);
  }
  handleCartVehicleLabel();
  window.document.querySelector('#cm_CartPageVehicleDialog .close')?.click();
};

globalThis.Convermax.onCartDialogChange = ({ target }) => {
  if (target.name === 'vehicle-option') {
    const form = target.closest('.cm_vehicle-dialog-form');
    if (form) {
      form
        .querySelectorAll('.cm_tab')
        .forEach((tab) => tab.classList.toggle('active', tab.dataset.tab === target.value));
    }
  }
};

function setCustomVehicleAtShopifyCart(vehicleStr) {
  fetch(`${window?.Shopify.routes.root}cart/update.js`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({ attributes: { ['YMMText']: vehicleStr } }),
  }).catch(console.error);
}

function AfterInit() {
  if (isCartPage && !window.Convermax.isVehicleSelected() && !localStorage.getItem('cm-user-vehicle')) {
    window.document.querySelector('#cm_CartPageVehicleDialogButton .cm_button')?.click();
  }

  if (isCartPage) {
    const cartVehicleLabel = window.document.querySelector('#cm_cart-vehicle-label');
    const cartVehicleText = window.document.querySelector('#cm_cart-vehicle-label .cm_cart-vehicle-text');
    const cartVehicleReset = window.document.querySelector('#cm_cart-vehicle-label .cm_cart-vehicle-reset');

    if (cartVehicleLabel) {
      handleCartVehicleLabel();
      cartVehicleText.addEventListener('click', () => {
        window.document.querySelector('#cm_CartPageVehicleDialogButton .cm_button')?.click();
      });
      cartVehicleReset.addEventListener('click', () => {
        window.Convermax.discardVehicle();
        localStorage.removeItem('cm-user-vehicle');
        window.document.querySelector('#cm_CartPageVehicleDialogButton .cm_button')?.click();
        handleCartVehicleLabel();
      });
    }
  }
}

function handleCartVehicleLabel() {
  const cartVehicleLabel = window.document.querySelector('#cm_cart-vehicle-label');
  const cartVehicleText = window.document.querySelector('#cm_cart-vehicle-label .cm_cart-vehicle-text');

  const vehicle = Object.values(window.Convermax.getVehicle()).join(' ');
  const savedVehicle = localStorage.getItem('cm-user-vehicle');

  cartVehicleText.textContent = window.Convermax.isVehicleSelected()
    ? vehicle
    : savedVehicle || 'Please Select a Vehicle';

  if (!window.Convermax.isVehicleSelected() && !savedVehicle) {
    cartVehicleLabel.classList.add('cm_cart-vehicle-label__empty');
  } else {
    cartVehicleLabel.classList.remove('cm_cart-vehicle-label__empty');
  }
}

const InitFunc = () => {
  shopifyGenericDefaults.InitFunc?.();

  const productOptions = window.document.querySelectorAll(
    'body.hdt-page-type-product hdt-variant-picker input',
  );

  productOptions?.forEach((element) => {
    element.addEventListener('change', () => {
      setTimeout(() => {
        window.Convermax.updateVerifyFitmentWidget(getItemId());
      }, 300);
    });
  });
};

export default {
  includes: ['shopify-generic'],
  ...shopifyGenericDefaults,
  InitFunc,
  AfterInit,
  product: {
    ...shopifyGenericDefaults.product,
    getItemId,
  },
  fitmentSearch: {
    ...shopifyGenericDefaults.fitmentSearch,
    forceVehicleSelection: true,
    forceVehicleSelectionInterval: 0,
    isAutoRedirectDisabled: isCartPage,
    isAutoVehicleSelectionDisabled: isCartPage,
  },
  Widgets: [
    ...shopifyGenericDefaults.Widgets.filter((widget) => !['DialogVehicleWidget'].includes(widget.name)),
    {
      name: 'DialogVehicleWidget',
      type: 'VehicleWidget',
      doNotRedirectOnVehicleSelect: isCartPage,
    },
    {
      name: 'CartPageVehicleDialog',
      type: 'ContextDialog',
      template: 'fitmentSearch/cartPageVehicleDialog',
    },
    {
      name: 'CartPageVehicleDialogButton',
      type: 'DialogButton',
      location: {
        lastChildOf: 'body',
        class: 'cm_hide',
      },
      template: 'fitmentSearch/customDialogButton',
      dialogName: 'CartPageVehicleDialog',
      metadata: { vehicleWidgetIncluded: true },
      visibleIf: () => document.querySelector('#MainContent > .hdt-section-main-cart'),
    },
  ],
};
